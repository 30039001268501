
export const content = {
    menu: {
        product: 'Product introduction',
        company: 'Company overview',
        services: 'Services Features',
        creatorCommunity: 'Creator Community',
        download: 'Download',
        bookNow: 'Book now',
        inquireNow: 'Inquire now',
        aboutUs: 'About us',
        sdk: 'Future Developer Alliance',
        about:'Contact Us',
        privacy:'Privacy Policy',
        userAgreementen:'User Agreement',
    },
    home: {
        floor2: {
            name: 'Crosser',
            description: 'TRAVEL LIGHT, FASHIONABLY AND WITNESS THE SPECTACULAR ON YOUR GLASSES',
            btn1: 'Book now',
            btn2: 'Learn more'
        }
    },
    through: {
        floor1: {
            name: ["Future Glasses", "Crosser"],
            description: "TRAVEL LIGHT, FASHIONABLY,AND WITNESS THE SPECTACULAR ON YOUR GLASSES.",
            btn: "Book now"
        },
        floor2: {
            name: "Ultra thin and light form fashionable and comfortable",
            description: "Create a zero-pressure wearing experience, suitable for long-term use",
            weight: "Weight only"
        },
        floor3: {
            name: "Use your phone while keeping your head up to reduce the load on your neck",
            description: "Looking down at your phone can cause stress on your neck. Crosser projects phone content onto a 200-inch screen, allowing you to use your phone with your head up, thus reducing the strain on your neck."
        },
        floor4: {
            name: "Small space can accommodate grand scenes",
            description: "Virtual imaging is not limited by the size of space."
        },
        floor5: {
            name: "Activate CR mode for synchronized real-time and virtual presentation",
            description: "Both virtual and real worlds are displayed simultaneously, allowing you to walk and enjoy movies at the same time."
        },
        floor6: {
            name: "Ultra-High brightness, more clear for outdoor use",
            description: "With 700nit ultra-high display brightness, you can still see clearly even in bright sunlight."
        },
        floor7: {
            name: "Support 3D display bring a new visual impact",
            description: "3D display technology provides an immersive sensory experience, allowing you to fully immerse yourself in your own movies, games, stories, and more."
        },
        floor8: {
            name: ["Compatible with mainstream devices", "expand to large screen anytime"],
            description: "Supports connection with external devices such as smartphones, gaming consoles, and more, further expanding user scenarios and experiences."
        },
        floor9: {
            name: "Carry it with ease, meeting various scene needs",
            description: "Compact size, no burden to carry, to meet the needs of more scenes, business travel.",
            img: ["AR movie watching", "AR Sports Watching", "AR office", "AR Gaming"]
        },
        floor10: {
            name: "Ensure the privacy of the use process and play freely",
            description: "All content is only presented on the glasses to protect the privacy of the user during use."
        }
    },
    companyProfile: {
        floor1: {
            title: ['About', 'us'],
            description: 'We are an emerging company located in Singapore, dedicated to providing exciting experiences and services. Based on the belief that human happiness is derived from consciousness, we are designing a parallel world known as the Metaverse.'
        },
        floor2: {
            title: ['Corporate', 'purpose'],
            description: [
                'We use love and technology to empower people in the infinite metaverse',
                'Where thoughts are free — dreams come true',
                'Privacy is kept confidential — enjoyed in solitude',
                'And Life is beautiful — filled with happiness and joy'
            ]
        },
        floor3: {
            title: ['Primary', 'mission'],
            description: 'To upgrade 2D videos and other media into a wonderful era of 3D videos using unique high-tech solutions like CR Vision, 3D cameras, FOS, and more. Our aim is to enhance various life experiences in the real world.'
        },
        floor4: {
            title: ['Company', 'vision'],
            description: 'To enable people to create and share real spiritual wealth in the virtual parallel world.',
            imgs: ['Web.3', 'Blockchain', 'PGC', 'UGC', 'AIGC', 'short video']
        },
        floor5: {
            title: ['Metaverse migration', 'vision'],
            description: 'To create ID identities, build beautiful homes, compress eternal data, and let humanity return to the joyful eternal Eden.Metaverse'
        }
    },
    servicesFeatures: {
        floor1: {
            img: ''
        },
        floor2: {
            title: 'Customized migration experience',
            description: "We offer a unique metaverse migration experience for each customer, tailored to their individual needs and dreams. Whether it's down-to-earth living or ambitious life goals, we can meet your expectations."
        },
        floor3: {
            title: 'Technological innovation',
            description: "We utilize cutting-edge virtual reality technology and artificial intelligence to provide customers with immersive Metaverse experiences. We closely collaborate with leading tech partners to ensure our services are always at the forefront of technology."
        },
        floor4: {
            title: 'Social interaction',
            description: "We encourage social interaction by offering virtual social opportunities. You can connect with friends, family, and a global community in the Metaverse, exploring the virtual world together."
        },
        floor5: {
            title: 'Safety and privacy',
            description: "We prioritize the safety and privacy of our customers. We implement strict data protection measures to ensure the security of customer information in the virtual world."
        }
    },
    creatorCommunity: {
        floor1: {
            img: "",
            title: "UGC Sharing",
            description: "Every consumer has the opportunity to gain unexpected benefits in sharing, advertising, blind boxes, and other projects. "
        },
        floor2: {
            title: "",
            description: "This is a space filled with creativity and limitless possibilities, allowing you to collaborate and share your PGC (User-Generated Content) works. Here, you can turn your ideas into virtual reality, share them with global Metaverse explorers, and explore endless creative opportunities."
        },
        floor3: {
            img: ""
        },
        floor4: [
            {
                title: "Creation and sharing",
                description: "In our creators' community, you can easily upload, showcase, and share your PGC works, whether they are virtual artworks, scenes, interactive games, or other creative content."
            },
            {
                title: "Learning and collaboration",
                description: "Exchange experiences with other Metaverse creators, learn together about technology and creative methods, and unleash your creativity in collaborative projects to achieve greater visions."
            },
            {
                title: "Rewards and recognition",
                description: "We value community members' contributions. Based on your works and involvement, you have the chance to receive rewards and recognition while building your reputation in the Metaverse field."
            },
            {
                title: "Technical support",
                description: "We provide technical support and resources for community members to help you realize your creativity and ensure the best experience for your PGC works in the Metaverse."
            },
            {
                title: "Global connection",
                description: "Connect with Metaverse enthusiasts from around the world, explore different cultures and creativity, and embark on a global adventure in the Metaverse together."
            }
        ]
        ,
        floor5: {
            title: "We encourage every creator, whether an amateur enthusiast or a professional artist, to join our community and shape the future of the Metaverse with us.",
            description: "The era of unlimited creativity has arrived; let's create the future together!"
        },
    },
    ar: {
        floor1: {
            title: "Connecting Future series glasses and FutureOS",
            description: "Future AR serves as the launch core of Future 0S, responsible for user management and interaction. t is presented in the form of an app and acts as a key to launching FOS. Additionally, Future AR includes a 3D space, an app store, and a mall."
        },
        floor2: [
            {
                title: "App store",
                description: "Provide the development entrance for all meta-universe players, support U3D development interface, and provide 3D APP enthusiasts and professional 3D development teams to implant APP into FOS. Future application management is mainly used to manage applications that need to run in the future intelligent mirror. APPs that need to be run on the future must be downloaded, installed and run through the future application management."
            },
            {
                title: "3D space",
                description: "Provide original universe video content enthusiasts to upload 3D video content, panoramic video content, or high-definition video content to FOS, and enjoy their own large screen and 3D works. Unprecedented."
            }
        ]
    },
    sdk:{
        floor1: {
            title: "Unity SDK for M. Vision + Android phones, providing tools for Unity developers to build AR applications",
            btn: "Download the ARSDK"
        },
        floor2: {
            title: "Provide the following capabilities",
            description: "The SDK is based on Google CardBoard",
            img:["Binocular stereo rendering","M.vision head tracking","Online speech recognition","Virtual touch interaction"]
        },
        floor3: {
            title: "It uses the binocular stereo rendering capability of the M. Vision screen to truly restore the 3D scene and object.",
            description: "Compatibility with Google Cardboard SDK and glasses."
        },
        floor4: {
            title: "Utilizing IMU sensor data from the glasses to track the user's 3DoF head movement.",
            description: "IMU sensor within the glasses, along with sensor data-based posture algorithms."
        },
        floor5: {
            title: "The online voice solution provided by FOS is a voice interaction mode for developers and users.",
            description: "Support for Chinese voice commands Recognition capabilities based on a voice recognition library Microphone for Android devices"
        },
        floor6: {
            title: "Touch interaction for developers and users.",
            description: "Ring control, link ring, control through virtual ray Mobile phone touch control, the 3Dof data of the mobile phone is converted into rays in the 3D space for control Offering VRControllerInputModule for events and ray detection."
        }
    },
    appointment:{
        title:["Future Glass Crosser","Reserved information","We will contact you as soon as the goods arrive"],
        text:["Please enter your email","Please enter your phone number","Please enter the name of the consignee","Please enter the shipping address"],
        btn:"Submit",
        alertPanel:["You have successfully made an appointment","Confirm"]
    },
    enquireNow:{
        title:"If you would like to learn more about our products, please leave your contact information.",
        text:[["Name","Please enter your name"],["Phone","Please enter your mobile phone number"],["Message","Please enter the content you want to inquire about"]],
        btn:"Submission"
    },
    about :{
        phone:"Telephone & What'sApp",
        email:"Service email",
        address:"Company address",
        shopAddress:"Shop address",
        title:"Our location",
        officeAddress:"Office Address"
    }
}