import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import $ from 'jquery'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import 'animate.css'

import { i18n } from './languages/index' 

Vue.config.productionTip = false
window.$ = $

new Vue({
  router,
  store,
  i18n, //使用国际化
  render: h => h(App)
}).$mount('#app')


import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAnh8Xh71KfVFfp95ZfC6u7Wt30hYtU390',
    libraries: 'places',
    region: 'sg', // 地区
    language: 'en' // 语言
  },
  installComponents: true
})

